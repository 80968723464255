import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { authTokenSelector } from "../recoil/atoms/userAtom";
import axios from "axios";
import EditVideoDetails from "../components/verifyVideo/EditVideoDetails";
import { toast } from "react-hot-toast";
import useLogout from "../hooks/useLogout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import videoStatusEnums from "../enums/videoStatusEnums";

export default function VerifyVideo() {
  const logoutUser = useLogout();

  const token = useRecoilValue(authTokenSelector);

  const [status, setStatus] = useState(0);
  const [resultList, setResultList] = useState([]);
  const [videoList, setVideoList] = useState(null);
  const [videoDetails, setVideoDetails] = useState(null);
  const [startDate, setStartDate] = useState(new Date());

  const getResultList = async () => {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}video2/resultlist`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const { data: res } = response;
        if (res.status == 200) {
          setResultList(res.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response?.status == 401) {
            toast.error("Please Login!");
            logoutUser();
          } else {
            toast.error("Request failed!");
          }
        }
      });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const fdt = event.target.fdt.value;
    getVideoList(status, fdt);
  };

  const getVideoList = async (status, fdt) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}video2/videolist`,
        { verify: status, fdt: fdt },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        const { data: res } = response;
        if (res.status == 200) {
          setVideoList(res.data);
        } else {
          setVideoList(null);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response?.status == 401) {
            toast.error("Please Login!");
            logoutUser();
          } else {
            setVideoList(null);
            toast.error("Request failed!");
          }
        }
      });
  };

  useEffect(() => {
    getResultList();
    // getVideoList(status);
  }, []);

  const changeStatus = async (event) => {
    setStatus(event.target.value);
    // getVideoList(event.target.value);
  };

  const showVideoDetails = async (data) => {
    // console.log(data);
    setVideoDetails(data);
  };

  const afterVerifyApiCall = () => {
    // console.log("after Verify Api Call");
    getVideoList(status);
    resetVideoEdit();
  };

  const resetVideoEdit = () => {
    // console.log("reset Video Edit");
    setVideoDetails(null);
  };

  const tableColspan = () => {
    if (status == 0) {
      return 7;
    } else if (status == 1) {
      return 9;
    } else if (status == 2) {
      return 10;
    } else {
      return 7;
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title fw-semibold mb-4">Verify Video</h5>
        <div className="card">
          <div className="card-body">
            <form autoComplete="off" onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="status" className="form-label">
                      Status
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      id="status"
                      value={status}
                      onChange={changeStatus}
                    >
                      <option value={0}>{videoStatusEnums[0]}</option>
                      <option value={1}>{videoStatusEnums[1]}</option>
                      <option value={2}>{videoStatusEnums[2]}</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4 verify-dt">
                  <label className="form-label">Select Date:</label>
                  <div className="d-block">
                    <DatePicker
                      className="form-control"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      name="fdt"
                      dateFormat={"yyyy-MM-dd"}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <label className="d-block form-label">&nbsp;</label>
                  <button type="submit" className="btn btn-primary btn-block">
                    Submit
                  </button>
                </div>
              </div>
            </form>

            <div className="row">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Duration</th>
                      <th>Pause</th>
                      <th>Result</th>
                      <th>Bowler Type</th>
                      <th>Upload User</th>
                      <th>Upload Date</th>
                      {status == 1 && (
                        <>
                          <th>Verify User</th>
                          <th>Verify Date</th>
                        </>
                      )}
                      {status == 2 && (
                        <>
                          <th>Delete User</th>
                          <th>Delete Date</th>
                          <th>Remark</th>
                        </>
                      )}
                      <th>Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(videoList &&
                      videoList.length &&
                      videoList.map((video, key) => (
                        <tr key={key}>
                          <td>{video.vname}</td>
                          <td>{video.dur}</td>
                          <td>{video.pause}</td>
                          <td>{video.result}</td>
                          <td>{video.btype}</td>
                          <td>{video.iuser}</td>
                          <td>{video.idt}</td>
                          {status == 1 && (
                            <>
                              <td>{video.vuser}</td>
                              <td>{video.vdt}</td>
                            </>
                          )}
                          {status == 2 && (
                            <>
                              <td>{video.duser}</td>
                              <td>{video.ddt}</td>
                              <td>{video.remark}</td>
                            </>
                          )}
                          <td>
                            <button
                              className="btn btn-info btn-sm"
                              onClick={() => showVideoDetails(video)}
                            >
                              View more
                            </button>
                          </td>
                        </tr>
                      ))) || (
                      <tr>
                        <td colSpan={tableColspan()} className="text-center">
                          There are no videos found!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {videoDetails && (
                <EditVideoDetails
                  resultList={resultList}
                  video={videoDetails}
                  token={token}
                  resetVideoEdit={resetVideoEdit}
                  afterVerifyApiCall={afterVerifyApiCall}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
